import React from "react";
import { Row, Col, Spin, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import apiCall from "../common/apiCall";

import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";

const createSessionToken = async (host) => {
  let api_key = process.env.REACT_APP_PARTNERAPP_APIKEY;
  if (host) {
    const app = createApp({
      apiKey: api_key,
      host: host
    });
    const sessionToken = await getSessionToken(app);
    return sessionToken;
  } else {
    return false;
  }
};

message.config({
  maxCount: 1
});

export default class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metrics: {},
      loading: true
    };
  }
  fetchData = async () => {
    try {
      this.setState({ loading: true });
      let getData = await apiCall.post("getStoreData", { token: await createSessionToken(this.state.host) });
      if (getData.data.status) {
        this.setState({
          metrics: getData.data.metrics
        });
        this.setState({ loading: false });
      } else {
        message.error("Some issue occured!");
        this.setState({ loading: false });
      }
    } catch (error) {
      message.error("Some error occured! Please reload the page!");
      console.log(error.response ? error.response.data : error);
      this.setState({ loading: false });
    }
  };
  componentDidMount() {
    let token = this.props.data ? this.props.data.token : "";
    this.setState({ host: token }, () => {
      this.fetchData();
    });
  }
  render() {
    var { metrics, loading } = this.state;
    return (
      <React.Fragment>
        {loading ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
            <Spin />
          </div>
        ) : (
          <div className="analytics">
            <Row gutter={{ xs: 15, sm: 15, md: 15, lg: 30 }} align="center">
              <Col xs={12} md={6}>
                <div className="analytics-thumbnails">
                  <div className="align-center">
                    <span className="circle">
                      <FontAwesomeIcon icon="user" style={{ color: "#0081FF" }} />
                    </span>
                    <h3>{metrics.userSessions ? metrics.userSessions : 0}</h3>
                    <h6>Number Of Users Sessions</h6>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="analytics-thumbnails">
                  <div className="align-center">
                    <span className="circle" style={{ background: "rgba(9, 182, 109, 0.17)" }}>
                      <FontAwesomeIcon icon="dollar-sign" style={{ color: "#09B66D" }} />
                    </span>
                    <h3>{metrics.totalSales ? metrics.totalSales : 0}</h3>
                    <h6>Total Sales</h6>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="analytics-thumbnails">
                  <div className="align-center">
                    <span className="circle" style={{ background: "rgba(255,61,87,.17)" }}>
                      <FontAwesomeIcon icon="cart-plus" style={{ color: "#FF3D57" }} />
                    </span>
                    <h3>{metrics.addToCartTotal ? metrics.addToCartTotal : 0}</h3>
                    <h6>Add To Cart Total</h6>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="analytics-thumbnails">
                  <div className="align-center">
                    <span className="circle">
                      <FontAwesomeIcon icon="mobile" style={{ color: "#FF3D57" }} />
                    </span>
                    <h3>{metrics.screenViews ? metrics.screenViews : 0}</h3>
                    <h6>Screen Views</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={{ xs: 15, sm: 15, md: 15, lg: 30 }} align="center" className="secondthumnail">
              <Col xs={12} md={6}>
                <div className="analytics-thumbnails">
                  <div className="align-center">
                    <span className="circle">
                      <FontAwesomeIcon icon="search" style={{ color: "#FF3D57" }} />
                    </span>
                    <h3>{metrics.noOfSearches ? metrics.noOfSearches : 0}</h3>
                    <h6>Number Of Searches</h6>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="analytics-thumbnails">
                  <div className="align-center">
                    <span className="circle">
                      <AndroidOutlined style={{ color: "#3DDC84", fontSize: "26px", fontWeight: "700" }} />
                    </span>
                    <h3>{metrics.androidSubscriptions ? metrics.androidSubscriptions : 0}</h3>
                    <h6>Android Subscriptions</h6>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <div className="analytics-thumbnails">
                  <div className="align-center">
                    <span className="circle">
                      <AppleOutlined style={{ color: "089cf5 ", fontSize: "26px" }} />
                    </span>
                    <h3>{metrics.iosSubscriptions ? metrics.iosSubscriptions : 0}</h3>
                    <h6>iOS Subscriptions</h6>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <div className="analytics-thumbnails last-thumbnail">
                  <div className="align-center">
                    <span className="circle">
                      <FontAwesomeIcon icon="bell" style={{ color: "#FF3D57" }} />
                    </span>
                    <h3>{metrics.totalPushSubscriptions ? metrics.totalPushSubscriptions : 0}</h3>
                    <h6 style={{ fontSize: "16px" }}>Total Push Subscriptions</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="white-section">
                  <div className="setup-instructions" style={{ textAlign: "left" }}>
                    <h3 className="title-page">
                      <strong>Setup Instructions</strong>
                    </h3>
                    <h4>Let's get started by following this guide</h4>
                    <div className="steps-rows" style={{ textAlign: "left" }}>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 1</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Click the Content icon (2nd icon) in our app dashboard to upload your store logo and to
                              select 2 base colours that we can use in the mobile app{" "}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 2</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Click the Contact Details icon (3rd icon) in our app dashboard and fill out the form so
                              that we can reach out to you to share the app and its details.{" "}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 3</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Click the Theme icon (4th icon) in our app dashboard and select any of our pre-built
                              default themes for your mobile app. In case you wish a new theme custom built for your
                              app, no issues, our team will help you out with that.{" "}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 4</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Boom! That's all. Once we have received all the above details, our server will
                              automatically create your mobile app, and after testing, our team will share the mobile
                              app over to your over email
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 5</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Head over to https://developer.apple.com/programs/ to register on Apple App Store. Head
                              over to https://play.google.com/console/about/ to register on Google Play Store Console.
                              (Incase you're facing difficulty over registering to AppStore or PlayStore, our team will
                              help you out for this! ){" "}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 6</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Once you've registered on both the accounts and verified your mobile app, we will deploy
                              your app to your accounts.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={{ xs: 15, sm: 15, md: 15, lg: 30 }} align="left">
              <Col xs={25} md={24}>
                <div className="white-section">
                  <div className="terms_and_conditions">
                    <h3 className="title-page">
                      <strong>Important</strong>
                    </h3>
                    <div className="play-stores">
                      <h4>
                        <strong>GOOGLE PLAY STORE</strong>
                      </h4>
                      <p>
                        As an open platform, Android offers different choices to distribute apps. Even though other
                        alternative Stores exist, Google Play is the premier platform to distribute an Android app.
                      </p>
                      <ul>
                        <li>
                          <p>
                            <span>
                              <b>Google Developer Account -</b>
                            </span>
                            In order to publish your app on the Google Play Store, it is mandatory to create a&nbsp;
                            <u style={{ color: "#000", borderBottom: "1px solid #000" }}>Google Developer Account</u>.
                            The registration fee is a one-time payment of $25.
                          </p>
                          <p>
                            For more information click on&nbsp;
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://appinventiv.com/blog/how-to-submit-app-to-google-play-store/">
                              (https://appinventiv.com/blog/how-to-submit-app-to-google-play-store/)
                            </a>
                          </p>
                        </li>
                      </ul>
                      <div className="submit-content">
                        <p>
                          <b>Approval / Rejection of apps on the play store are the sole decision of Google</b>. If a
                          user opens your app listing they will take only a few seconds to decide if it matches their
                          need. You need to make your listing compelling to catch their attention and convince them to
                          download your app. It can take a few hours for your app to be available on the Play Store.
                        </p>
                      </div>
                    </div>
                    <div className="play-stores">
                      <h4>
                        <strong>App Store (Apple Store)</strong>
                      </h4>
                      <p>
                        The process of submitting an app to the App Store is slightly complex and requires throughout
                        preparation. Apple has set high standards and strict guiding principles in order to maintain
                        this ecosystem safe for its users and always offer high quality apps.
                      </p>
                      <ul>
                        <li>
                          <p>
                            <span>
                              <b> Apple Developer Account -</b>
                            </span>
                            - In order to create an iOS app and publish it on the App Store, you are required to
                            register as an Apple Developer . This account cost $99 and must be renewed yearly. Joining
                            this developer program also gives you the ability to build an iOS app and test it on the
                            devices themselves, a crucial step in the app development process.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>
                              <b> submission of App To AppStore -</b>
                            </span>
                            Approval / Rejection of apps on the play store are the sole decision of Apple. We will take
                            care of making sure all the required information is provided to the best to maximise the
                            chance of app approval. It is highly recommended to read the Apple Store Review guidelines
                            before starting the submission process.
                          </p>
                          <p>
                            ore information click on&nbsp;
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://appinventiv.com/blog/apple-app-store-submissions-checklist/">
                              (https://appinventiv.com/blog/apple-app-store-submissions-checklist/)
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  }
}
