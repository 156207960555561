import React from "react";
import { Layout, Row, Col, Avatar, Typography } from "antd";
const { Header } = Layout;

const { Title } = Typography;

export default class HeaderDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shopName: ""
    };
  }

  componentDidMount() {
    let queries = new URLSearchParams(window.location.search);
    let shopName = queries.get("shop") ? queries.get("shop").split(".")[0] : "";
    this.setState({ shopName });
  }

  state = {
    current: "analytics"
  };
  handleClick = (e) => {
    console.log("click ", e);
    this.setState({ current: e.key });
  };
  render() {
    return (
      <Header style={{ boxShadow: "0 10px 30px 0 rgb(47 60 74 / 8%)", position: "relative" }}>
        <Row>
          <Col span={12} align="left">
            <Title level={3} style={{ color: "#fff", marginBottom: "0px", lineHeight: "64px" }}>
              <a href="/">
                <img src="/img/logo.png" alt="Logo" style={{ height: "50px" }} />
              </a>
            </Title>
          </Col>

          <Col span={12} align="right">
            <div className="flex-wrapper" style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf", textTransform: "uppercase" }}>
                {this.state.shopName ? this.state.shopName.charAt(0) : "F"}
              </Avatar>
              <h4 style={{ margin: "0 0 0 10px" }}>
                <strong style={{ textTransform: "capitalize" }}>
                  {this.state.shopName ? this.state.shopName : "WebToApp"}
                </strong>
              </h4>
            </div>
          </Col>
        </Row>
      </Header>
    );
  }
}
