import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form, Input, Row, Col, message, Spin } from "antd";
import apiCall from "../common/apiCall";

import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";

const createSessionToken = async (host) => {
  let api_key = process.env.REACT_APP_PARTNERAPP_APIKEY;
  if (host) {
    const app = createApp({
      apiKey: api_key,
      host: host
    });
    const sessionToken = await getSessionToken(app);
    return sessionToken;
  } else {
    return false;
  }
};

message.config({
  maxCount: 1
});

export default class ContactDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      number: "",
      skypeId: "",
      loading: true
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    let token = this.props.data ? this.props.data.token : "";
    this.setState({ host: token });
    this.setState({ loading: false });
  }

  handleSubmit = async () => {
    let { email, name, number, skypeId } = this.state;
    let data = { email, name, number, skypeId, token: await createSessionToken(this.state.host) };
    if (!email || !name) return;
    try {
      this.setState({ loading: true });
      const response = await apiCall.post("createSccContact", data);
      if (response.data.status) {
        message.success("Updated!");
        this.setState({ loading: false });
      } else {
        message.error("Some error occured!");
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error.response ? error.response.data : error);
      message.error("Some error occured! Please reload the page!");
      this.setState({ loading: false });
    }
  };

  render() {
    let { email, name, number, skypeId, loading } = this.state;
    return (
      <React.Fragment>
        {loading ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
            <Spin />
          </div>
        ) : (
          <div className="contact-details" style={{ background: "#fff", boxShadow: "0 8px 32px rgb(47 60 74 / 1%)" }}>
            <div className="heading-page">
              <Row>
                <Col span={24}>
                  <div className="title">
                    <h2>
                      <b>Contact Details</b>
                    </h2>
                    <p>
                      Please share your contact details so that we can reach out to your after your mobile app is ready.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Row align="center">
              <Col md={8} lg={10} xl={8}>
                <div className="form-wrapper">
                  <Form method="post">
                    <Form.Item>
                      <Input
                        required
                        value={name}
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                        placeholder="Contact Name"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Input
                        required
                        placeholder="Contact Email"
                        value={email}
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <PhoneInput
                        required
                        type="tel"
                        inputProps={{
                          name: "WhatsApp phone number",
                          required: true
                        }}
                        inputClass=""
                        // placeholder="WhatsApp phone number"
                        enableSearch={true}
                        country={"us"}
                        value={number}
                        onChange={(value) => {
                          this.setState({ number: value });
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Input
                        placeholder="Skype"
                        value={skypeId}
                        onChange={(e) => {
                          this.setState({ skypeId: e.target.value });
                        }}
                      />
                    </Form.Item>
                    <Form.Item style={{ width: "100%", textAlign: "center" }}>
                      <button
                        className="dashbtn"
                        onClick={() => {
                          this.handleSubmit();
                        }}>
                        Submit
                      </button>
                    </Form.Item>
                  </Form>
                  <div className="note" style={{ textAlign: "center", color: "#000" }}>
                    <p>
                      Note - we require these details to reach out to you for <br />
                      completing your custom mobile app.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  }
}
