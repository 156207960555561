import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faDollarSign,
  faCartPlus,
  faMobile,
  faSearch,
  faChartBar,
  faBell,
  faCloudUploadAlt
} from "@fortawesome/free-solid-svg-icons";

library.add(faUser, faDollarSign, faCartPlus, faMobile, faSearch, faChartBar, faBell, faCloudUploadAlt);
