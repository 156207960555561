import React from "react";
import { Row, Col, message, Spin } from "antd";
import apiCall from "../common/apiCall";

import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";

const createSessionToken = async (host) => {
  let api_key = process.env.REACT_APP_PARTNERAPP_APIKEY;
  if (host) {
    const app = createApp({
      apiKey: api_key,
      host: host
    });
    const sessionToken = await getSessionToken(app);
    return sessionToken;
  } else {
    return false;
  }
};

message.config({
  maxCount: 1
});

export default class SelectTheme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      themes: [
        {
          name: "theme1",
          image: "/img/themes/theme1.png"
        },
        {
          name: "theme2",
          image: "/img/themes/theme2.png"
        },
        {
          name: "theme3",
          image: "/img/themes/theme3.png"
        },
        {
          name: "theme4",
          image: "/img/themes/theme4.png"
        }
      ],
      selectedTheme: "",
      loading: true
    };
  }

  saveTheme = async () => {
    var { selectedTheme } = this.state;
    if (!selectedTheme) {
      message.warning("Please select a theme");
      return;
    }
    let data = {
      theme: selectedTheme,
      token: await createSessionToken(this.state.host)
    };
    try {
      this.setState({ loading: true });
      const result = await apiCall.post("saveStoreTheme", data);
      if (result.data.status) {
        message.success("Updated!");
        this.setState({ loading: false });
      } else {
        message.error("Some error occured!");
        this.setState({ loading: false });
      }
    } catch (error) {
      message.error("Some error occured! Please reload the page!");
      console.log(error.response ? error.response.data : error);
      this.setState({ loading: false });
    }
  };

  fetchData = async () => {
    try {
      this.setState({ loading: true });
      let getData = await apiCall.post("getStoreData", { token: await createSessionToken(this.state.host) });
      if (getData.data.status) {
        this.setState({
          selectedTheme: getData.data.theme
        });
        this.setState({ loading: false });
      } else {
        message.error("Some issue occured!");
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error.response ? error.response.data : error);
      message.error("Some error occured! Please reload the page!");
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    let token = this.props.data ? this.props.data.token : "";
    this.setState({ host: token }, () => {
      this.fetchData();
    });
  }

  render() {
    var { loading, themes, selectedTheme } = this.state;
    return (
      <React.Fragment>
        {loading ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
            <Spin />
          </div>
        ) : (
          <div className="inner-pages select-theme">
            <div className="heading-page">
              <Row>
                <Col xs={12} sm={12}>
                  <div className="title">
                    <h2>
                      <b>Select Theme</b>
                    </h2>
                    <p>
                      Please select any of our pre-built themes to apply to your mobile app. You may change these even
                      after your mobile app is built
                    </p>
                  </div>
                </Col>
                <Col xs={12} sm={12} align="right" className="selectthem-btnwrap">
                  <button
                    onClick={(e) => {
                      this.saveTheme();
                    }}
                    className="dashbtn">
                    Save Theme
                  </button>
                </Col>
              </Row>
            </div>
            <div className="thumbnails-wrapper">
              <Row gutter={[15, 15]} align="center">
                {themes.map((item) => {
                  return (
                    <Col xs={12} sm={12} md={6} className="theme-wrapper" key={item.name}>
                      <div className={selectedTheme === item.name ? "withBorder" : "noBorder"}>
                        <img
                          src={item.image}
                          id={item.name}
                          alt={item.name}
                          onClick={(e) =>
                            this.setState({
                              selectedTheme: item.name
                            })
                          }
                        />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
