import React from "react";
import "../loading.css";
import apiCall from "../common/apiCall";
import { Row, Col } from "antd";

export default class Load extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      link: "http"
    };
  }
  async sendQuery() {
    try {
      let code = new URLSearchParams(window.location.search).get("code");
      let shop = new URLSearchParams(window.location.search).get("shop");
      let hmac = new URLSearchParams(window.location.search).get("hmac");
      let param = {
        code,
        shop,
        searchLocation: window.location.search
      };
      if (hmac && code && shop) {
        let req = await apiCall.post("generateToken", param);
        if (req.data.status) {
          alert("Congratulations! Our App has been installed!");
          this.setState({ link: "https://" + shop + "/admin/apps/webtoapp" });
          this.setState({ loading: false });
          window.history.pushState({ path: "" }, "", "?token=generated");
          // window.location.href = "https://" + shop + "/admin/apps/webtoapp";
        } else {
          alert("Some error occured! Please try installing our app again!");
          window.location.href = "https://webtoapp.fleapo.com";
        }
      } else {
        alert("Some error occured! Please try installing our app again!");
        window.location.href = "https://webtoapp.fleapo.com";
      }
    } catch (error) {
      console.log(error);
      alert("Some error occured! Please try installing our app again!");
      window.location.href = "https://webtoapp.fleapo.com";
    }
  }

  componentDidMount() {
    this.sendQuery();
  }

  render() {
    var { loading, link } = this.state;
    return (
      <React.Fragment>
        <style jsx>
          {`
            header,
            aside {
              display: none !important;
            }
          `}
        </style>
        {loading ? (
          <div
            className="cover"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              overflow: "hidden",
              width: "100%"
            }}>
            <div className="boxes">
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <Row>
              <Col span={24}>
                <div className="white-section">
                  <div className="setup-instructions" style={{ textAlign: "left" }}>
                    <h3 className="title-page">
                      <strong>Congratulations!</strong>
                    </h3>
                    <h4>Thank you for installing WebToApp. Let's create your mobile app only in 4 simple steps - </h4>
                    <div className="steps-rows" style={{ textAlign: "left" }}>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 1</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Go to your shopify dashboard, and find and click the option "WebToApp" in the side under
                              Sales Channels. Or <a href={link}> click here </a> to open our app dashboard{" "}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 2</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Click the Content icon (2nd icon) in our app dashboard to upload your store logo and to
                              select 2 base colours that we can use in the mobile app{" "}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 3</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Click the Contact Details icon (3rd icon) in our app dashboard and fill out the form so
                              that we can reach out to you to share the app and its details.{" "}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 4</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Click the Theme icon (4th icon) in our app dashboard and select any of our pre-built
                              default themes for your mobile app. In case you wish a new theme custom built for your
                              app, no issues, our team will help you out with that.{" "}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 5</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Boom! That's all. Once we have received all the above details, our server will
                              automatically create your mobile app, and after testing, our team will share the mobile
                              app over to your over email
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 6</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Head over to https://developer.apple.com/programs/ to register on Apple App Store. Head
                              over to https://play.google.com/console/about/ to register on Google Play Store Console.
                              (Incase you're facing difficulty over registering to AppStore or PlayStore, our team will
                              help you out for this! ){" "}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}>
                          <h5 className="step-name">Step 7</h5>
                        </Col>
                        <Col md={22}>
                          <div className="step-text">
                            <p>
                              Once you've registered on both the accounts and verified your mobile app, we will deploy
                              your app to your accounts.
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={24}>
                          <h5 className="step-name">
                            Note Below - Approval / Rejection of apps on the play store or appstore are the sole
                            decision of Google and Apple respectively. We will take care of making sure all the required
                            information is provided to the best to maximise the chance of app approval.
                          </h5>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
