import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Tooltip } from "antd";
import { ContactsOutlined, BarChartOutlined, EditOutlined, ProfileOutlined } from "@ant-design/icons";

const { Sider } = Layout;

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "analytics"
    };
  }

  handleClick = (e) => {
    this.setState({ current: e.key });
  };

  render() {
    const { current } = this.state;
    return (
      <Sider className="sidebar" width={70}>
        <Menu mode="inline" onClick={this.handleClick} selectedKeys={[current]}>
          <Menu.Item key="analytics" icon={<BarChartOutlined />}>
            <Tooltip placement="right" title={"Analytics"}>
              <Link to="/"></Link>
            </Tooltip>
          </Menu.Item>
          <Menu.Item key="content" icon={<ProfileOutlined />}>
            <Tooltip placement="right" title={"Content"}>
              <Link to="/content"></Link>
            </Tooltip>
          </Menu.Item>
          <Menu.Item key="contactdetails" icon={<ContactsOutlined />}>
            <Tooltip placement="right" title={"Contact Details"}>
              <Link to="/contactdetails"></Link>
            </Tooltip>
          </Menu.Item>
          <Menu.Item key="selectheme" icon={<EditOutlined />}>
            <Tooltip placement="right" title={"Select Theme"}>
              <Link to="/select-theme"></Link>
            </Tooltip>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}
