import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./fontawesome/fontawesome";
import "antd/dist/antd.css";
import "./dashboard.css";

import { Spin, Layout } from "antd";

import HeaderDashboard from "./header/header";
import Sidebar from "./sidebar/sidebar";

import Analytics from "./pages/analytics";
import Contents from "./pages/contents";
import ContactDetails from "./pages/contactdetails";
import SelectTheme from "./pages/selecttheme";
import VerifyInstall from "./pages/verifyInstall";

const { Content } = Layout;

const checkHmacValidity = require("shopify-hmac-validation").checkHmacValidity;

export default class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      host: ""
    };
  }

  fetchParams = async () => {
    let api_key = process.env.REACT_APP_PARTNERAPP_APIKEY;
    let redirect_uri = encodeURI(process.env.REACT_APP_PARTNERAPP_REDIRECTURL);
    let scopes = process.env.REACT_APP_PARTNERAPP_SCOPES;

    let queries = new URLSearchParams(window.location.search);
    let shop = queries.get("shop");
    let hmac = queries.get("hmac");
    let timestamp = queries.get("timestamp");
    let host = queries.get("host");
    let session = queries.get("session");
    if (shop && hmac && timestamp && host && session) {
      let check = checkHmacValidity(process.env.REACT_APP_PARTNERAPP_SECRET, window.location.search);
      if (check) {
        this.setState({ host }, () => {
          this.setState({ loading: false });
        });
      } else {
        alert("Some issue occured! Please reload the page!");
        return;
      }
    } else if (shop && hmac && timestamp) {
      let check = checkHmacValidity(process.env.REACT_APP_PARTNERAPP_SECRET, window.location.search);
      if (check) {
        const install_url = `https://${shop}/admin/oauth/authorize?client_id=${api_key}&scope=${scopes}&redirect_uri=${redirect_uri}`;
        window.location.href = install_url;
      } else {
        alert("Unauthenticated Request!");
        window.location.href = "https://webtoapp.fleapo.com";
        return;
      }
    } else {
      alert("Unauthenticated Request!");
      window.location.href = "https://webtoapp.fleapo.com";
      return;
    }
  };

  componentDidMount = async () => {
    if (window.location.href.includes("/verify-install")) this.setState({ loading: false });
    else this.fetchParams();
  };

  render() {
    var { host, loading } = this.state;
    return (
      <Router>
        <Switch>
          {loading ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Spin />
            </div>
          ) : (
            <Layout style={{ height: "100vh" }}>
              <HeaderDashboard />
              <Layout>
                <Sidebar />
                <Layout className="site-layout">
                  <Content
                    className="site-layout-background"
                    style={{
                      background: "#F2F4F4",
                      padding: "15px",
                      overflowY: "scroll"
                    }}>
                    <Route exact path="/" component={() => <Analytics data={{ token: host }} />} />
                    <Route exact path="/content" component={() => <Contents data={{ token: host }} />} />
                    <Route exact path="/contactdetails" component={() => <ContactDetails data={{ token: host }} />} />
                    <Route exact path="/select-theme" component={() => <SelectTheme data={{ token: host }} />} />
                    <Route exact path="/verify-install" component={VerifyInstall} />
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          )}
        </Switch>
      </Router>
    );
  }
}
